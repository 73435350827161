// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("modernizr/modernizr.custom.js");
// require("@rails/ujs").start()
window.$ = window.jQuery = require('jquery');
// require('jquery-ui');
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

require("@fortawesome/fontawesome-free")
require("daterangepicker/daterangepicker")

//--- Bootstrap
import 'bootstrap';
import 'chosen-js/chosen.css';
import appInit from '../angle/app-init.js';
// import 'bootstrap-select/dist/js/bootstrap-select'
document.addEventListener('DOMContentLoaded', appInit);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//= require rails-ujs

// class_calendar - new
